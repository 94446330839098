import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import { StaticQuery, graphql } from "gatsby";

// import Header from "./header"
import "./layout.css";
import favs from "./favicons.js";


export default class Layout extends PureComponent {
  timeout = null;

  // export default class Layout = ({ children }) => (
  componentDidMount() {
    let pos = 0;
    this.timeout = setInterval(function() {
        pos = !pos;
        document.querySelector('[rel=icon]').href = favs[+pos];
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <Fragment>
            {this.props.children}
            <Helmet
              title="FarmAloha: 8.10.2019"
              link={[
                  { rel: 'icon', type: 'image/png', href: favs[0] }
              ]}
            />
          </Fragment>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};